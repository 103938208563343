.swal-confirm{
  background-color: #cfff48;
  color: #000;
  border: 0;
  width: 60px;
  padding: 12px;
  border-radius: 0.25em;
  border-color: #fff;
  font-size: 1em;
  
  cursor: pointer;
}

.swal-error{
  background-color: #fff;
  color: #000;
  border: 0;
  width: 60px;
  padding: 12px;
  border-radius: 0.25em;
  border-color: #fff;
  font-size: 1em;
  
  cursor: pointer;
}

body{
  padding: 0;
  margin: 0;
}

.App {
  height: 1px;
  width: 1px;
  overflow: visible;
  text-align: center;
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 0px;
  background-color: white;
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}

.container {
  height: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: static;
  right: 5%;
  top: 5%;    
}

.connect-wallet {  
  display: flex;
  width: 15vw;
  height: 2.5vw;

  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2vw;
  background-color: #fff;
  font-family: Cofosans, sans-serif;
  color: #000;
  font-size: 0.8vw;
  line-height: 1;
  cursor: pointer;
  position: fixed;
  top: 7vh;
  right: 5vw;
}

.claim-nft {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 15vw;
  height: 2.5vw;

  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2vw;
  background-color: #cfff48;
  font-family: Cofosans, sans-serif;
  color: #000;
  font-size: 0.8vw;
  line-height: 1;
  cursor: pointer;
  position: fixed;
  bottom: 5vh;
  right: 32vw;
}

/* loading */
*,
*:before,
*:after {
  box-sizing: border-box;
}

.loading {
  width: 100vw;
  /* height: 100%;  */
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: .5rem;
  row-gap: 1.5rem;
  position: fixed;
  top: 50%;
}

@media screen and (max-width: 479px) {
  .loading {
    width: 100vw;    
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: .5rem;
    row-gap: 1.5rem;
    position: fixed;
    top: 50%;
    margin-left: -10vw;     
  }

  .connect-wallet {  
    display: flex;
    width: 40vw;
    height: 5vh;
    max-height: 50px;
    max-width: 250px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 2vw;
    background-color: #fff;
    font-family: Cofosans, sans-serif;
    color: #000;
    font-size: 3.5vw;
    line-height: 1;
    cursor: pointer;
    position: fixed;
    top: 10vh;
    right: 5vw;
  }

  .claim-nft {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 90vw;
    height: 5vh;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 2vw;
    background-color: #cfff48;
    font-family: Cofosans, sans-serif;
    color: #000;
    font-size: 5vw;
    line-height: 1;
    cursor: pointer;
    position: fixed;
    bottom: 3vh;
    left: 5vw;
  }
}

.loading:after {
  /* content: "Logging you in"; */
  color: #fff;
  flex: 0 100%;
  font: 700 1.3rem "Caveat", cursive;
  text-align: center;
}

.loading div {
  background-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: loading-effect 1s cubic-bezier(.77, .8, .58, 1) infinite
    var(--delay, .2s) alternate-reverse;
}

.loading div:nth-child(2) {
  --delay: .4s;
}
.loading div:nth-child(3) {
  --delay: .6s;
}
.loading div:nth-child(4) {
  --delay: .8s;
}
.loading div:nth-child(5) {
  --delay: 1s;
}

@keyframes loading-effect {
  0% {
    box-shadow: 0 0 4px 1px #fff3;
    opacity: .2;
    transform: translateY(3px) scale(1.1);
  }

  100% {
    opacity: .8;
    transform: translateY(-3px);
  }
}